<template>
  <tasks-gantt-chart
    v-if="dhmeProjectAssemblyTasks.length > 0"
    :highlighted-items="highlightedTasksIds"
    :tasks="dhmeProjectAssemblyTasks"
    :vacation-days="dhmeProjectAssemblyPlanningCalendar"
  >
    <template #task-header-items>
      <tasks-gantt-default-item
        v-for="(task, index) in dhmeProjectAssemblyTasks"
        :key="`ant-gantt-header-${task.id}`"
        :can-load-children="false"
        :index="index"
        :parents="[]"
        :task="task"
      />
    </template>
  </tasks-gantt-chart>
  <div v-else class="d-flex align-center justify-center flex-1">
    Generate assembly planning in the top right
  </div>
</template>

<script>
import TasksGanttChart from '@/components/Charts/TasksGanttChart.vue';
import TasksGanttDefaultItem from '@/components/Charts/TasksGanttDefaultItem.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'DhmeProjectAssemblyPlanningAutomated',
  components: { TasksGanttDefaultItem, TasksGanttChart },
  props: {
    highlightedModuleType: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters([
      'dhmeProjectAssemblyTasks',
      'dhmeProjectAssemblyPlanningModules',
      'dhmeProjectAssemblyPlanningCalendar',
    ]),

    highlightedTasksIds() {
      return this.dhmeProjectAssemblyTasks
        .filter((t) =>
          this.dhmeProjectAssemblyPlanningModules.some(
            (m) =>
              m.module_id === t?.sbscode?.code &&
              m.module_type === this.highlightedModuleType
          )
        )
        .map((t) => t.id);
    },
  },
};
</script>

<style scoped></style>
